<template>
  <div>
    <Loader/>
    <section class="sign-in-page">
      <div id="container-inside">
        <div id="circle-small"></div>
        <div id="circle-medium"></div>
        <div id="circle-large"></div>
        <div id="circle-xlarge"></div>
        <div id="circle-xxlarge"></div>
      </div>
      <div class="container">
        <div id="form" class="panel-body">
          <div class="form-group">
            <label class="control-label col-sm-2">{{ $t("security.login_name") }}</label>
            <div class="col-sm-5">
              <input class="form-control" type="text" name="first_name" v-model="username">
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-2">{{ $t("security.login_password") }}</label>
            <div class="col-sm-5">
              <input class="form-control" type="password" name="password" v-model="password">
            </div>
          </div>
          <div class="form-group">
            <label class="control-label col-sm-2"> </label>
            <div class="col-sm-5">
              <input type="button" @click='login();' value="Přihlásit" class="btn btn-primar">
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>
<script>
import Loader from '../../components/socialvue/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import {socialvue} from '../../config/pluginInit'
import bgImage from '../../assets/images/login/login-2.jpg'
import Store from "@/store";
import api from "@/api/api";

export default {
  name: 'AuthLayout1',

  status: null,
  components: {
    Loader
  },
  mounted() {
    socialvue.index()
    // eslint-disable-next-line no-console
    console.log(this.$store.getters["Setting/authUserState"])
  },
  data() {
    return {
      username: null,
      password: null,
      slickOptions: {
        slidesToShow: 1,
        arrows: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  },
  methods: {
    login() {
      api
          .post("customer/login", {
            email: this.username,
            password: this.password
          })
          .then((response) => {
            Store.dispatch('Setting/authUserAction', response)
          })
    }
  }
}
</script>

<style scoped>
#form {
  padding-top: 100px;
}

label {
  color: white !important;
}

input[type="text"], input[type="password"] {
  background-color: white !important;
}

input[type="button"] {
  background-color: white !important;
  color: black !important;
}
</style>